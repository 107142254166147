<template>
  <b-navbar toggleable="xl" type="dark">
    <b-navbar-brand :to="{ name: 'home' }">
      <img src="@/assets/img/logo.png" />
    </b-navbar-brand>

    <NavbarContentRight
      v-if="me"
      :userId="me.id"
      :searchQuery="searchQuery"
      @searchQueryChange="searchQuery = $event"
    />

    <b-navbar-toggle target="nav-collapse"
      ><div id="nav-icon">
        <span></span>
        <span></span>
        <span></span>
        <span></span></div
    ></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="nav-main" v-if="!formActive">
        <b-nav-item :to="{ name: 'ressorts' }">Gremien</b-nav-item>
        <li class="nav-item external-no-icon">
          <a
            href="https://impulsant-dsag.de/"
            rel="noopener"
            target="_blank"
            class="nav-link external-no-icon"
            >Impulsant</a
          >
        </li>
        <b-nav-item :to="{ name: 'global-news' }">News</b-nav-item>
        <b-nav-item :to="{ name: 'events' }">Veranstaltungen</b-nav-item>
        <b-nav-item
          href="https://dsag.de/mitgliedschaft/vorteile/"
          target="_blank"
          >Vorteile</b-nav-item
        >
        <b-nav-item-dropdown>
          <template #button-content>Academy</template>
          <b-dropdown-item
            :to="{ name: 'gremium', params: { id: 'dsag-academy-forum' } }"
          >
            DSAG-Academy-Forum
          </b-dropdown-item>
          <li role="presentation">
            <a
              href="https://dsagnet.de/content/sap-educate-to-employ-die-initiative-gegen-den-it-fachkraftemangel-2"
              rel="noopener"
              target="_blank"
              class="dropdown-item"
              >SAP Educate to Employ</a
            >
          </li>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          v-if="
            !preventAdminLinks &&
            me &&
            me.company &&
            me.company.membership &&
            me.company.membership.active &&
            permissions['partner.show']
          "
        >
          <template #button-content>Partnerangebote</template>
          <b-dropdown-item :to="{ name: 'partner' }">
            Partnerdatenbank
          </b-dropdown-item>
          <li role="presentation">
            <a
              href="https://dsagnet.de/gremium/forum-fur-partner"
              target="_blank"
              class="dropdown-item"
              >Forum für Partner</a
            >
          </li>
          <li role="presentation">
            <a
              href="https://dsagnet.de/content/angebote-von-partnern-im-sap-umfeld"
              target="_blank"
              class="dropdown-item"
              >Angebote von Partnern</a
            >
          </li>
          <li role="presentation">
            <a
              href="https://dsagnet.de/content/dsag-angebote-fur-partner"
              target="_blank"
              class="dropdown-item"
              >Angebote für Partner</a
            >
          </li>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          left
          v-if="me && checkAvailability()"
          class="administration"
        >
          <template #button-content> Admin </template>
          <b-dropdown-item :to="{ name: 'admin.cockpit' }">
            Cockpit
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin.company.list' }">
            Firmen
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin.user.list' }">
            Mitglieder
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin.gremium' }">
            Gremien
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'admin.events' }">
            Veranstaltungen
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right class="hide-desktop-nav" v-if="me">
          <template #button-content v-if="me">Profil </template>
          <b-dropdown-item
            v-if="me"
            :to="{
              name: 'me',
            }"
          >
            Mein Profil
          </b-dropdown-item>
          <b-dropdown-item
            v-if="me.company"
            :to="{
              name: 'mycompany',
            }"
          >
            Meine Firma
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="logout()">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-nav-form
        :class="[{ active: formActive }]"
        v-if="me && searchVisible"
        class="hide-mobile"
        @submit.stop.prevent="submitForm()"
      >
        <b-form-input
          size="sm"
          class="mr-sm-2"
          placeholder="Suchen"
          v-model="searchQuery"
        >
        </b-form-input>
        <b-button size="sm" type="submit" @click="submitForm()">
          <i class="fal fa-search"></i>
        </b-button>
      </b-nav-form>
      <NavbarContentRight v-if="me" :user-id="me.id" />
      <div class="navbar-nav ml-auto nav-actions" v-else>
        <b-button variant="orange" class="icon-right" @click="$logout"
          >Jetzt einloggen<i class="fa-regular fa-right-to-bracket"></i
        ></b-button>
      </div>
    </b-collapse>
  </b-navbar>
</template>
<script>
import NavbarContentRight from "./NavbarContentRight.vue";
import gql from "graphql-tag";

const permArray = [
  "admin.show",
  "partnerDb.admin",
  "matching.admin",
  "matching.show",
  "partner.show",
];

export default {
  components: {
    NavbarContentRight,
  },
  props: ["searchVisible"],
  emits: ["searchChange", "searchSubmit"],
  data() {
    return {
      formActive: false,
      searchQuery: "",
      preventAdminLinks: true,
    };
  },
  watch: {
    searchQuery: {
      handler() {
        this.$emit("searchChange", this.searchQuery);
      },
    },
    me(val) {
      if (!val) {
        setTimeout(() => {
          if (!this.me) {
            this.$logout();
          }
        }, 5000);
      }
    },
  },
  apollo: {
    me: {
      query: gql`
        query Me {
          me {
            id
            state
            roles {
              id
            }
            company {
              id
              membership {
                id
                state
                active
                confirmed
              }
            }
          }
        }
      `,
      update(data) {
        if (data === undefined) {
          setTimeout(() => {
            if (!this.me) {
              this.$logout();
            }
          }, 1000);
        }
        return data.me;
      },
      fetchPolicy: "network-only",
    },
  },
  methods: {
    checkAvailability() {
      let roles = JSON.parse(JSON.stringify(this.me.roles));
      let rolesMapped = roles.map(({ id }) => id);
      if (
        rolesMapped.includes(process.env.VUE_APP_GS_ROLE_ID) ||
        rolesMapped.includes(process.env.VUE_APP_BILLING_ROLE_ID)
      ) {
        return true;
      } else {
        return false;
      }
    },
    logout() {
      this.$logout();
    },
    submitForm() {
      this.$emit("searchSubmit", this.searchQuery);
    },
  },
  mounted() {
    const interval = setInterval(() => {
      for (const perm of permArray) {
        const allowed = this.permissions[perm];
        if (allowed !== null && allowed !== undefined) {
          this.preventAdminLinks = false;
          clearInterval(interval);
          break;
        }
      }
    }, 50);
  },
  loadPermissions() {
    return permArray;
  },
};
</script>
