<template>
  <div class="header-actions" :class="{ 'golden-master': isGoldenMaster }">
    <ul class="icon-menu">
      <li>
        <a
          href="https://www.linkedin.com/company/dsag/mycompany/"
          target="_blank"
          class="external-no-icon"
          ><i class="fa-brands fa-linkedin-in"></i
        ></a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/dsag.de/"
          target="_blank"
          class="external-no-icon"
          ><i class="fa-brands fa-instagram"></i></a>
      </li>
      <li>
        <a
          href="https://www.xing.com/pages/dsag"
          target="_blank"
          class="external-no-icon"
          ><i class="fa-brands fa-xing"></i
        ></a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/c/dsagev"
          target="_blank"
          class="external-no-icon"
          ><i class="fa-brands fa-youtube"></i
        ></a>
      </li>
    </ul>
    <ul>
      <li>
        <a target="_blank" href="https://dsag.de"
          ><i class="fa-regular fa-arrow-right"></i>DSAG.de</a
        >
      </li>
      <li>
        <a @click="logout()"><i class="fa-regular fa-arrow-right"></i>Logout</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    isGoldenMaster() {
      return process.env.VUE_APP_IS_GOLDEN_MASTER === "true";
    },
  },
  methods: {
    logout() {
      this.$logout();
    },
  },
};
</script>
